import React, {useRef, useState, useEffect} from 'react';
import Action from '../Action/Action';
import data from '../../js/products.json';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Products = () => {
  const ref = useRef();
  const [products] = useState(data);
  const [cardWidth, setCardWidth] = useState(400);
  const windowWidth = window.innerWidth;
  const [current, setCurrent] = useState(0);
  const [sliderLength, setSliderLength] = useState(data.length);
  const sliderWidth = (data.length * cardWidth);

  const moveSlider = (dir, current) => {
    const slider = ref.current;
    // console.log(current);
    // console.log(sliderLength);

    if(dir === 'right') {
      if(current < (sliderLength)) {
        slider.style.left = `-${(cardWidth) * current}px`;
        setCurrent(current);
      }
    }else if(dir === 'left') {
      if(current > 0) {
        slider.style.left = `-${(cardWidth) * current}px`;
        setCurrent(current);
      }
    }
  }

  useEffect(() => {
    if(windowWidth >= 1900) {
      setSliderLength(10);
    }else if(windowWidth >= 1280) {
      setSliderLength(13);
    }else if(windowWidth >= 768) {
      setSliderLength(14);
    }else if(windowWidth >= 400) {
      setCardWidth(400);
    }else if(windowWidth >= 375) {
      setCardWidth(375);
    }else if(windowWidth >= 360) {
      setCardWidth(360);
    }
    ref.current.style.width = `${sliderWidth}px`;
  }, [sliderLength, windowWidth, cardWidth, sliderWidth]);
  
  useEffect(() => {
    gsap.to('.bs-products', {
      duration: 2,
      opacity: 1,
      translateX: '50px',
      // scrub: true,
      scrollTrigger: {
        trigger: '.bs-products',
        start: "-=500px",
        // markers: true,
      },
    })
  });

  return (
    <div id='products' className='bs-products'>
      <h2 className='bs-section--heading'>Our beignets are inspired by a world of flavors</h2>
      <Action href="https://www.clover.com/online-ordering/beignet-spot-riverside-2" label="Order Now" />

      <div className='bs-products--slider'>
        <button className='bs-products--slider_next'
          onClick={() => moveSlider('right', (current + 1))}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 38.17" 
            style={
              {
                height: '24px',
                left: '35%',
                position: 'absolute',
                top: '35%',
                transform: 'transform(-50%, -50%)',
                width: '24px'
              }
            }>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
              <polyline className="cls-1" points="2.13 2 17.45 19.09 2 36.17" style={
                {
                  fill: 'none', 
                  stroke: '#231f20',
                  strokeLinecap: 'round',
                  strokeMiterlimit: '10',
                  strokeWidth: '4px',
                }
              }/>
              </g>
            </g>
          </svg>
        </button>

        <div ref={ref} className='bs-products--slider_content'>
          {products.map((product, index) => (
            <div className='bs-products--slider_card' 
              key={product.id}
              style={
                {
                  backgroundImage: `url(${product.src})`,
                  width: `${cardWidth}px`,
                }
              }
              data-media={product.id}>
              <div className="bs-products--slider_label">
                <span className="bs-products--slider_text">{product.name}</span>
              </div>
            </div>
          ))}
        </div>
        
        <button className='bs-products--slider_prev'
          onClick={() => moveSlider('left', (current - 1))}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.14 38.17" 
              style={
                {
                  height: '24px',
                  left: '45%',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translate(-50%, -50%) rotate(-180deg)',
                  width: '24px'
                }
              }>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                <polyline className="cls-1" points="2.13 2 17.45 19.09 2 36.17" style={
                  {
                    fill: 'none', 
                    stroke: '#231f20',
                    strokeLinecap: 'round',
                    strokeMiterlimit: '10',
                    strokeWidth: '4px',
                  }
                }/>
                </g>
              </g>
            </svg>
          </button>
      </div>
    </div>
  )
}

export default Products;