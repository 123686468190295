import React, {useEffect, useState, useRef} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Social = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [posLimit, setPosLimit] = useState(14);
  const [multiplier, setMultiplier] = useState(1);
  let current = 0;
  const offset = (300 * multiplier);
  const ref = useRef()

  const moveSlider = (dir) => {
    const slider = ref.current;
    if(dir === 'right') {
      if(current < posLimit) {
        current++;
        const delta = -(current * offset) + 'px';
        slider.style.left = delta;
        // console.log('current', current);
      }
    }else if(dir === 'left') {
      if(current > 0) {
        current--;
        const delta = -(current * offset) + 'px';
        slider.style.left = delta;
        // console.log('current', current);
      }
    }
  }

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    if(screenWidth >= 2560) {
      setPosLimit(4);
      setMultiplier(1.5);
    }else if(screenWidth >= 1900) {
      setPosLimit(6);
      setMultiplier(1.5);
    }else if(screenWidth >= 1440) {
      setPosLimit(5);
      setMultiplier(2);
    }else if(screenWidth >= 1280) {
      setPosLimit(3);
      setMultiplier(4);
    }else if(screenWidth >= 1024) {
      setPosLimit(6);
      setMultiplier(2);
    }else if(screenWidth >= 768) {
      setPosLimit(13);
      setMultiplier(1);
    }else if(screenWidth >= 320) {
      setMultiplier(1);
    }
  }, [screenWidth]);

  useEffect(() => {
    gsap.to('.bs-social--slider', {
      duration: 3,
      opacity: 1,
      translateX: '-50px',
      scrollTrigger: {
        trigger: '.bs-social',
        start: "bottom bottom",
        // markers: true,
      },
    });
  });

  return (
    <div id='social' className='bs-social'>
      <p className='bs-social--header'>Follow us on Instagram</p>
      
      <div className='bs-social--slider'>
        <button className='bs-social--slider_next'
          onClick={() => moveSlider('right')}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M8.793 5h1.414l7 7-7 7H8.793l7-7z"/>
            <path fill="none" d="M0 0h24v24H0z"/>
          </svg>
        </button>

        <div ref={ref} className='bs-social--slider_content'>
          <div className='bs-social--slider_card' data-media="1"></div>
          <div className='bs-social--slider_card' data-media="2"></div>
          <div className='bs-social--slider_card' data-media="3"></div>
          <div className='bs-social--slider_card' data-media="4"></div>
          <div className='bs-social--slider_card' data-media="5"></div>
          <div className='bs-social--slider_card' data-media="6"></div>
          <div className='bs-social--slider_card' data-media="7"></div>
          <div className='bs-social--slider_card' data-media="8"></div>
          <div className='bs-social--slider_card' data-media="9"></div>
          <div className='bs-social--slider_card' data-media="10"></div>
          <div className='bs-social--slider_card' data-media="11"></div>
          <div className='bs-social--slider_card' data-media="12"></div>
          <div className='bs-social--slider_card' data-media="13"></div>
          <div className='bs-social--slider_card' data-media="14"></div>
          <div className='bs-social--slider_card' data-media="15"></div>
        </div>
        
        <button className='bs-social--slider_prev'
          onClick={() => moveSlider('left')}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M6.793 12l7-7h1.414l-7 7 7 7h-1.414z"/>
            <path fill="none" d="M0 0h24v24H0z"/>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default Social;