import React, {useEffect} from 'react';
import Action from '../Action/Action';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Location = () => {
  const windowWidth = window.innerWidth;

  useEffect(() => {
    if(windowWidth >= 1024) {
      gsap.to('.bs-location--content', {
        duration: 3,
        opacity: 1,
        translateX: '25px',
        scrollTrigger: {
          trigger: '.bs-location',
          start: "-=400px",
          // markers: true,
        },
      });
    }else{
      gsap.to('.bs-location--content', {
        duration: 3,
        opacity: 1,
        translateX: '50px',
        scrollTrigger: {
          trigger: '.bs-location',
          start: "-=400px",
          // markers: true,
        },
      });
    }
  }, [windowWidth]);

  return (
    <div id='location' className='bs-location'>
      <div className='bs-location--content'>
        <h2 className='bs-section--heading large'>Fresh Beignets Are Just Around The Corner</h2>
        {/* <img src='../text/bs-text-h1-fresh-beignets.png' 
            height='auto' 
            width='100%' 
            style={{display: 'flex', margin: '0 auto'}} alt='' /> */}
        <Action href="https://goo.gl/maps/k84WK4SqxdQfL4ZP7" label="Find a location" />
      </div>
    </div>
  )
}

export default Location;