import React, {useEffect, useRef} from 'react';
import gsap from "gsap";

const Character = () => {
  const characterButton = useRef(null);

  useEffect(() => {
    gsap.to(characterButton.current, {
      delay: 3,
      duration: 3,
      opacity: 1
    });
  }, []);

  return (
    <div ref={characterButton} className='bs-character'>
      <div className='bs-character--thumb'></div>
    </div>
  )
}

export default Character;