import React, {useEffect} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Shop = (props) => {
  const windowWidth = window.innerWidth;

  useEffect(() => {
    if(windowWidth >= 1024) {
      gsap.to('.bs-shop--product', {
        duration: 3,
        opacity: 1,
        translateX: '60px',
        scrollTrigger: {
          trigger: '.bs-shop',
          start: "-=500px",
          // markers: true,
        },
      });
      
      gsap.to('.bs-shop--text_content', {
        duration: 3,
        opacity: 1,
        translateX: '-10%',
        scrollTrigger: {
          trigger: '.bs-shop',
          start: "-=500px",
          // markers: true,
        },
      });
    }else{
      gsap.to('.bs-shop--product', {
        duration: 3,
        opacity: 1,
        marginLeft: 0,
        scrollTrigger: {
          trigger: '.bs-shop',
          start: "-=500px",
          // markers: true,
        },
      });
      
      gsap.to('.bs-shop--text_content', {
        duration: 3,
        opacity: 1,
        translateX: '0',
        scrollTrigger: {
          trigger: '.bs-shop',
          start: "-=500px",
          // markers: true,
        },
      });
    }
  }, [windowWidth]);

  return (
    <div id='shop' className='bs-shop'>
      <div className='bs-shop--product'>
        <img className='bs-shop--product_shot' src='../images/bs-shop-fg-shirt.png' alt="Beignet - T-Shirt" />
      </div>
      <span className='bs-shop--sugar'></span>
      <div className='bs-shop--text'>
        <div className='bs-shop--text_content'>
          <h2 className='bs-shop--heading'>Beignet Nation</h2>
          <p className='bs-shop--header'>Shop Tasty Apparel From Our Merch Store</p>
          <p className='bs-shop--header'>...Coming Soon</p>
          {/* <Action label="Shop Beignet Nation" /> */}
        </div>
      </div>
    </div>
  )
}

export default Shop;