import React, {useEffect} from 'react';
import Featured from '../Featured/Featured';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Media = (props) => {
  useEffect(() => {
    gsap.to('.bs-media--header', {
      duration: 2,
      opacity: 1,
      translateX: '-50px',
      scrollTrigger: {
        trigger: '.bs-media--header',
        start: "-=500px",
        // markers: true,
      },
    })
    
    gsap.to('.bs-media--video', {
      duration: 2,
      opacity: 1,
      // scrub: true,
      scrollTrigger: {
        trigger: '.bs-media--header',
        start: "-=400px",
      },
    })
  }, [])
  
  return (
    <div className='bs-media'>
      <div className='bs-media--header'>
        <h2 className='bs-section--heading'>Beignets, breakfast, sandwiches, shakes and more served daily</h2>
      </div>
      <div className='bs-media--video'>
        <div className='bs-media--play' 
          onClick={() => props.setActive(true)}></div>
      </div>

      <Featured />
      <div className='bs-media--sugar'></div>
    </div>
  )
}

export default Media;