import React, {useEffect, useRef} from 'react';
import gsap from "gsap";

const Banner = () => {
  const textNow = useRef(null);
  const textOpen = useRef(null);
  const beignet = useRef(null);
  const windowWidth = window.innerWidth;
  
  useEffect(() => {
    if(windowWidth >= 1024) {
      gsap.to(textNow.current, {
        duration: 3,
        marginRight: '75px',
        opacity: 1
      });
      
      gsap.to(textOpen.current, {
        duration: 3,
        marginLeft: '75px',
        opacity: 1
      });
    
      gsap.to(beignet.current, {
        delay: 1,
        duration: 3,
        left: '50%',
        opacity: 1
      });
    }else{
      gsap.to(textNow.current, {
        duration: 3,
        marginRight: '0',
        opacity: 1
      });
      
      gsap.to(textOpen.current, {
        duration: 3,
        marginLeft: '0',
        opacity: 1
      });
    
      gsap.to(beignet.current, {
        delay: 1,
        duration: 3,
        left: '50%',
        opacity: 1
      });
    }
  });

  return (
    <div className='bs-announcement'>
      <h1 className='bs-announcement--text'>
        <span ref={textNow} className='bs-announcement--text_now'>Beignets</span> 
        {/* <span ref={textOpen} className='bs-announcement--text_open'>Beignets</span> */}
      </h1>
      <img ref={beignet} className='bs-announcement--beignet' src="./images/bs-banner-beignet-static.png" alt="Beignet Sugar" />
    </div>
  )
}

export default Banner;