import React from 'react';

const Modal = (props) => {
  return (
    <div className='bs-modal'>
      <div className='bs-modal--exit' onClick={() => props.setActive(false)}>
        <svg fill='#fff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M18.01 6.697L12.707 12l5.303 5.303-.707.707L12 12.707 6.697 18.01l-.707-.707L11.293 12 5.99 6.697l.707-.707L12 11.293l5.303-5.303z"/>
          <path fill="none" d="M0 0h24v24H0z"/>
        </svg>
      </div>
      <div className='bs-modal--content'>
        <video className='bs-modal--content_video' loop autoPlay>
          <source
            src="/video/bs-video-optim.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default Modal;