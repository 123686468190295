import React, {useEffect, useState, useRef} from 'react';
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const Menu = (props) => {
  const [active, setActive] = useState(false);
  const menuButton = useRef(null);

  const scrollToAnchor = (anchor) => {
    gsap.to(window, {duration: 2, scrollTo: `#${anchor}`});
    setActive(!active);
  }

  useEffect(() => {
    gsap.to(menuButton.current, {
      delay: 3,
      duration: 3,
      opacity: 1
    });
  }, []);

  return (
    <React.Fragment>
      <div ref={menuButton} className="bs-menu--button" 
        onClick={() => setActive(!active)} 
        data-active={active}>
        <div className="bs-menu--button_bar" data-position="1"></div>
        <div className="bs-menu--button_bar" data-position="2"></div>
        <div className="bs-menu--button_bar" data-position="3"></div>
      </div>
      
      <div className="bs-menu" data-active={active}>
        <div className="bs-menu--list_wrapper">
          <div className="bs-menu--list_inner">
            <ul className="bs-menu--list">
              <li className="bs-menu--list_item">
                <a className="bs-menu--list_anchor" 
                  href="./images/bspot-menu.pdf"
                  download
                >Menu</a>
              </li>
              <li className="bs-menu--list_item">
                <a className="bs-menu--list_anchor" 
                  href="./images/bspot-catering-menu.pdf"
                  download
                >Catering</a>
              </li>
              <li className="bs-menu--list_item">
                <a className="bs-menu--list_anchor" 
                  href="#story" 
                  onClick={(evt) => {
                    evt.preventDefault(); 
                    scrollToAnchor('story');
                  }}>Our Story</a>
              </li>
              <li className="bs-menu--list_item">
                <a className="bs-menu--list_anchor" 
                  href="#shop"
                  onClick={(evt) => {
                    evt.preventDefault(); 
                    scrollToAnchor('shop');
                  }}>Shop</a>
              </li>
              <li className="bs-menu--list_item">
                <a className="bs-menu--list_anchor" 
                  href="#location"
                  onClick={(evt) => {
                    evt.preventDefault(); 
                    scrollToAnchor('location');
                  }}>Location</a>
              </li>
              <li className="bs-menu--list_item">
                <a className="bs-menu--list_anchor" 
                  href="https://beignetspotfranchise.com">Franchise with Us</a>
              </li>
              <li className="bs-menu--list_item">
                <a className="bs-menu--list_anchor" 
                  href="#social"
                  onClick={(evt) => {
                    evt.preventDefault(); 
                    scrollToAnchor('social');
                  }}>Follow Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Menu;