import React, {useEffect, useRef} from 'react';
import gsap from "gsap";

const Action = ({href, label, type}) => {
  const actionButton = useRef(null);

  useEffect(() => {
    gsap.to(actionButton.current, {
      delay: 3,
      duration: 3,
      opacity: 1,
    });
  }, []);

  return (
    <div ref={actionButton} className={`bs-action ${type}`}>
      <a href={href} className='bs-action--primary' target="_blank" rel="noreferrer">{label}</a>
    </div>
  )
}

export default Action;