import React, {useEffect} from 'react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Title = (props) => {
  useEffect(() => {
    gsap.to('.bs-title--header', {
      duration: 2,
      opacity: 1,
      translateX: '-50px',
      // scrub: true,
      scrollTrigger: {
        trigger: '.bs-title',
        start: "-=500px",
        // markers: true,
      },
    })
  });

  return (
    <div id='story' className='bs-title'>
      <div className='bs-title--header'>
        <h2 className='bs-section--heading large'>Our Story</h2>
        {/* <img src='../text/bs-text-h1-our-story.png' alt='' /> */}
      </div>
    </div>
  )
}
    
export default Title;