import React, {useEffect} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import data from '../../data/split.data.json';

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Split = ({direction, section, children}) => {
  const windowWidth = window.innerWidth;

  useEffect(() => {
    if(windowWidth >= 1440) {
      gsap.to('.bs-split--poster[data-direction=left]', {
        duration: 3,
        opacity: 1,
        transform: 'translateX(75px) rotateZ(-3deg)',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
          // markers: true,
        },
      });
      
      gsap.to('.bs-split--poster[data-direction=right]', {
        duration: 3,
        opacity: 1,
        transform: 'translateX(-75px) rotateZ(3deg)',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
          // markers: true,
        },
      });

      gsap.to('.bs-split--text', {
        duration: 3,
        opacity: 1,
        translateX: '0px',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
        },
      })
      
      gsap.to('.bs-split--text[data-direction=right]', {
        duration: 3,
        opacity: 1,
        translateX: '0px',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
        },
      })
    }else if(windowWidth >= 1024) {
      gsap.to('.bs-split--poster[data-direction=left]', {
        duration: 3,
        opacity: 1,
        transform: 'translateX(15px) rotateZ(-3deg)',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
          // markers: true,
        },
      });
      
      gsap.to('.bs-split--poster[data-direction=right]', {
        duration: 3,
        opacity: 1,
        transform: 'translateX(-15px) rotateZ(3deg)',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
          // markers: true,
        },
      });

      gsap.to('.bs-split--text', {
        duration: 3,
        opacity: 1,
        translateX: '0px',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
        },
      })
      
      gsap.to('.bs-split--text[data-direction=right]', {
        duration: 3,
        opacity: 1,
        translateX: '0px',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
        },
      })
    }else{
      gsap.to('.bs-split--poster[data-direction=left]', {
        duration: 3,
        opacity: 1,
        translateX: 0,
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
          // markers: true,
        },
      });
      
      gsap.to('.bs-split--poster[data-direction=right]', {
        duration: 3,
        opacity: 1,
        translateX: 0,
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
          // markers: true,
        },
      });

      gsap.to('.bs-split--text', {
        duration: 3,
        opacity: 1,
        translateX: '0px',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
        },
      })
      
      gsap.to('.bs-split--text[data-direction=right]', {
        duration: 3,
        opacity: 1,
        translateX: '0px',
        scrollTrigger: {
          trigger: '.bs-split',
          start: "-=500px",
        },
      })
    }
  }, [windowWidth])
  
  return (    
    data.map(({
      id,
      direction,
      section,
      label,
      subLabel,
      description
    }) => (
      <div
        key={id}
        className='bs-split' 
        data-direction={direction}
      >
        <div
          className='bs-split--poster' 
          data-section={section}
          data-direction={direction}>
        </div>
        <div
          key={id}
          className='bs-split--text' 
          data-direction={direction}
        >
          <h2 className='bs-split--text_label'>{ label }</h2>
          <h3 className='bs-split--text_heading'>{ subLabel }</h3>
          <p className='bs-split--text_desc'>{ description }</p>
        </div>
      </div>
    ))
  )
}

export default Split;