import React, {useEffect} from 'react';
import Action from '../Action/Action';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Featured = () => {
  useEffect(() => {
    const windowWidth = window.innerWidth;
    if(windowWidth >= 1024) {
      gsap.to('.bs-featured--poster', {
        duration: 2,
        opacity: 1,
        marginLeft: 0,
        // scrub: true,
        scrollTrigger: {
          trigger: '.bs-featured',
          start: "-=300px",
        },
      })

      gsap.to('.bs-featured--poster_sugar', {
        duration: 2,
        opacity: 1,
        top: '-5%',
        // scrub: true,
        scrollTrigger: {
          trigger: '.bs-featured',
          start: "-=400px",
        },
      })
      
      gsap.to('.bs-featured--text', {
        duration: 2,
        opacity: 1,
        marginRight: 0,
        // scrub: true,
        scrollTrigger: {
          trigger: '.bs-featured',
          start: "-=300px",
        },
      }) 
    }else if(windowWidth >= 768) {
      gsap.to('.bs-featured--poster', {
        duration: 2,
        opacity: 1,
        marginLeft: '15%',
        // scrub: true,
        scrollTrigger: {
          trigger: '.bs-featured',
          start: "-=300px",
        },
      })

      gsap.to('.bs-featured--poster_sugar', {
        duration: 2,
        opacity: 1,
        top: '-5%',
        // scrub: true,
        scrollTrigger: {
          trigger: '.bs-featured',
          start: "-=400px",
        },
      })
      
      gsap.to('.bs-featured--text', {
        duration: 2,
        opacity: 1,
        marginRight: 0,
        // scrub: true,
        scrollTrigger: {
          trigger: '.bs-featured',
          start: "-=300px",
        },
      }) 
    }else {
      gsap.to('.bs-featured--poster', {
        duration: 2,
        opacity: 1,
        marginLeft: '0',
        // scrub: true,
        scrollTrigger: {
          trigger: '.bs-featured',
          start: "-=300px",
        },
      })

      gsap.to('.bs-featured--poster_sugar', {
        duration: 2,
        opacity: 1,
        top: '-5%',
        // scrub: true,
        scrollTrigger: {
          trigger: '.bs-featured',
          start: "-=400px",
        },
      })
      
      gsap.to('.bs-featured--text', {
        duration: 2,
        opacity: 1,
        marginRight: 0,
        // scrub: true,
        scrollTrigger: {
          trigger: '.bs-featured',
          start: "-=300px",
        },
      }) 
    }
  }, []);

  return (
    <div id='featured' className='bs-featured'>
      <div className='bs-featured--poster' data-section='about'></div>
      <div className='bs-featured--poster_sugar'></div>

      <div className='bs-featured--text'>
        <div className='bs-featured--content'>
          <h2 className='bs-featured--text_label'>MORE THAN BEIGNETS</h2>
          <h3 className='bs-featured--text_heading'>TRY OUR mouthwatering breakfast and lunch</h3>
          <p className='bs-featured--text_desc'>Beignets, breakfast, sandwiches, 
            drinks and other delicious sweets served daily.</p>
          <Action href="https://www.clover.com/online-ordering/beignet-spot-riverside-2" label="Order Now" />
        </div>
      </div>
    </div>
  )
}

export default Featured;