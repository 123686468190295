import React, {useState} from 'react';
import Layout from './components/Layout/Layout';
import Banner from './components/Banner/Banner';
import Media from './components/Media/Media';
import Modal from './components/Modal/Modal';
import Products from './components/Products/Products';
import Stories from './components/Stories/Stories';
import Title from './components/Title/Title';
import Shop from './components/Shop/Shop';
import Location from './components/Location/Location';
import Social from './components/Social/Social';
import Footer from './components/Footer/Footer';

import './styles/app.scss';

function App() {
  const [active, setActive] = useState(false);
  
  return (
    <Layout>
      {active && <Modal active={active} setActive={setActive} />}
      <Banner />
      <Media active={active} setActive={setActive} />
      <Products />
      <Title />
      <Stories />
      <Shop />
      <Location />
      <Social />
      <Footer />
    </Layout>
  );
}

export default App;