import React, {useEffect, useRef} from 'react';
import Logo from '../Logo/Logo';
import Announcement from '../Announcement/Announcement';
import Action from '../Action/Action';
import Menu from '../Menu/Menu';
import Character from '../Character/Character';
import gsap from "gsap";

const Banner = () => {
  const sugarRef = useRef(null);
  const sugarPowderRef = useRef(null);
  const windowWidth = window.innerWidth;
  
  useEffect(() => {
    if(windowWidth >= 1024) {
      gsap.to(sugarRef.current, {
        duration: 5,
        right: 0,
        opacity: 1,
        top: '50%'
      });
      
      gsap.to(sugarPowderRef.current, {
        duration: 4,
        right: 0,
        opacity: 1,
        top: '5%'
      });
    }else if(windowWidth >= 768) {
      gsap.to(sugarRef.current, {
        duration: 5,
        right: 0,
        opacity: .5,
        top: '0'
      });

      gsap.to(sugarPowderRef.current, {
        duration: 4,
        right: 0,
        opacity: .5,
        top: '0'
      });
    }else{
      gsap.to(sugarRef.current, {
        duration: 5,
        right: 0,
        opacity: .5,
        top: '0'
      });

      gsap.to(sugarPowderRef.current, {
        duration: 4,
        right: 0,
        opacity: .5,
        top: '0'
      });
    }
  }, [windowWidth]);

  return (
    <div className='bs-banner'>
      <Menu />
      <Action href="https://goo.gl/maps/k84WK4SqxdQfL4ZP7" label="Find a location" type="small" />
      <Character />
      <Logo />
      <Announcement />
      <Action href="https://www.beignetspotfranchise.com" label="Franchise with Us" type="normal" />
      <div ref={sugarRef} className='bs-banner--sugar'></div>
      <div ref={sugarPowderRef} className='bs-banner--sugar_powder'></div>
    </div>
  )
}

export default Banner;